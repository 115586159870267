header.banner {
  @include color-block('white');
  @include transition($link-transition);
  border-bottom: 1px solid rgba($orange,.2);
  z-index: 5;
  position:-webkit-sticky;
  position:fixed;
  top:0;
  left: 0;
  width: 100%;
  height: $header-height;
  .logged-in.admin-bar & {
    top: 32px;
    @media screen and ( max-width: 782px ) {
  		html { margin-top: 46px; }
  	}
  }
  .primary-elements {
    @include fluid-container-margins;
    padding-top: $grid-gutter-width/2;
    padding-bottom: $grid-gutter-width/2;
  }
  .primary-nav-engaging & {
    @include color-block('orange');
    height: 100%;
  }
  .primary-nav-engaged & {
    @include color-block('orange');
  }
  .logo {
    @include media-breakpoint-up(md) {
      justify-content: center;
    }
    .brand {
      display: block;
      img {
        max-height: 64px;
      }
    }
  }
  .toggle {
    justify-content: flex-end;
    .navbar-toggle {
      min-width: 6em;
      span {
        &.show {
          display: none;
        }
      }
      &.collapsed {
        span {
          display: none;
          &.show {
            display: inline;
          }
        }
      }
    }
    .apply {
      @include scaled-spacer('margin','right','xs');
      @include media-breakpoint-down(sm) {
        display: none;
      }
    }
  }
  #PrimaryNavigation {
    @include color-block('orange');
    position: absolute;
    height: calc(100% - #{$header-height});
    width: 100%;
    overflow: scroll;
    text-align: center;
    a {
      @include link-no-underline;
    }
    nav {
      &.nav-primary {
        @include heading-text-tight;
        font-size: $nav-sm-font-size;
        @include media-breakpoint-up(md) {
          font-size: $nav-md-font-size;
        }
        @include media-breakpoint-up(lg) {
          font-size: $nav-lg-font-size;
        }
        @include media-breakpoint-up(xl) {
          font-size: $nav-xl-font-size;
        }
        ul {
          @include list-normalize;
          li {
            @include scaled-spacer('margin','top','md');
            a {
              display: inline-block;
            }
          }
        }
      }
      &.nav-quicklinks {
        @include scaled-spacer('margin','top','lg');
        @include scaled-spacer('margin','bottom','md');
        @include heading2-text;
        font-weight: $font-weight-base;
        font-size: $font-size-xl;
        ul {
          @include list-normalize;
          display: inline-block;
          column-count: 2;
          column-gap: 1.25rem;
          li {
            @include scaled-spacer('margin','bottom','sm');
            line-height: 1.2;
            break-inside: avoid;
          }
        }
      }
    }
    .apply-link {
      @include scaled-spacer('margin','top','lg');
      @include media-breakpoint-up(md) {
        display: none;
      }
    }
  }
}

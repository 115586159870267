.wp-full-width {
  @include wp-full-width-no-padding;
}
.ccv-block {
  @include block-frame;
  @include scaled-spacer('padding','top','lg');
  @include scaled-spacer('padding','bottom','lg');
  @include scaled-spacer('margin','top','md');
  @include scaled-spacer('margin','bottom','md');
/*
  &+.ccv-block:not(.bg-white) {
    @include scaled-spacer-negative('margin','top','md');
  }
*/
  &.bg-white {
    @include scaled-spacer('padding','bottom','md');
    padding-top: 0;
    &+.ccv-block.bg-white {
//       @include scaled-spacer-negative('margin','top','lg');
    }
  }
  .chat-bubbles {
    @include scaled-spacer('padding','top','xxs');
    @include scaled-spacer('padding','bottom','md');
    &.bubble-2 {
      @include scaled-spacer('padding','bottom','xl');
      @include media-breakpoint-up(md) {
        @include scaled-spacer('padding','bottom','lg');
      }
    }
  }
  .overview {
    @include last-child-zero-margin;
    text-align: center;
    .style-svg {
      height: 64px;
      width: auto;
    }
    .style-svg,h2 {
      @include scaled-spacer('margin','bottom','xs');
    }
  }
}

main.main {
  @include scaled-spacer('padding','top','lg');
  @include scaled-spacer('padding','bottom','lg');
  &>h2:not(:first-child) {
    @include scaled-spacer('margin','top','lg');
  }
  &>h3 {
    @include scaled-spacer('margin','top','md');
  }
  :first-child {
    &.ccv-block.bg-white {
      margin-top: 0;
      padding-top: 0;
    }
  }
  :last-child {
    &.ccv-block {
      margin-bottom: 0;
      &.bg-white {
        padding-bottom: 0;
      }
    }
  }
}
.ctas-block {
  .cta-item {
    @include scaled-spacer('margin','bottom','sm');
    @include last-child-zero-margin;
    @include make-col-ready();
    @include make-col(12);
    @include media-breakpoint-up(md) {
      @include make-col(6);
    }
    figure {
      @include scaled-spacer('margin','bottom','sm');
      .style-svg {
        height: 90px;
        width: auto;
      }
    }
    .description {
      @include last-child-zero-margin;
      h3 {
        @include scaled-spacer('margin','bottom','xs');
      }
      .details {
        @include last-child-zero-margin;
      }
      .action {
        @include scaled-spacer('padding','top','xs');
      }
    }
  }
  &.cta-tiles-block {
    .cta-item {
      @include media-breakpoint-up(lg) {
        @include make-col(4);
      }
      figure {
        @include scaled-spacer('margin','bottom','xs');
      }
      .description {
        h3 {
          @include scaled-spacer('margin','bottom','xxs');
        }
      }
    }
  }
  &.cta-features-block {
    .cta-item {
      @include scaled-spacer('margin','bottom','md');
      text-align: center;
    }
  }
  &.cta-banners-block {
    .cta-item {
      &:not(:last-child) {
        @include scaled-spacer('margin','bottom','xl');
      }
      @include media-breakpoint-up(md) {
        @include make-col(12);
      }
      @include make-row();
      align-items: center;
      figure,.description {
        @include make-col-ready();
        @include make-col(12);
        @include media-breakpoint-up(md) {
          @include make-col(6);
        }
        @include media-breakpoint-up(lg) {
          @include make-col(5);
//           @include make-col-offset(1);
        }
      }
      figure {
        @include media-breakpoint-up(md) {
          margin-bottom: 0;
        }
      }
      .description {
        @include media-breakpoint-up(lg) {
          @include make-col(6);
        }
      }
      &:nth-child(odd) {
        flex-direction: row-reverse;
        @include media-breakpoint-up(lg) {
          figure {
            @include make-col-offset(1);
          }
        }
      }
      &:nth-child(even) {
        @include media-breakpoint-up(lg) {
          .description {
            @include make-col-offset(1);
          }
        }
      }
    }
  }
}
.headline-chat-bubbles-block {
  .overview {
    @include media-breakpoint-up(xl) {
      @include scaled-spacer('padding','left','md');
      @include scaled-spacer('padding','right','md');
    }
  }
}
.hero-carousel-block {
  padding: 0;
  .container-fluid {
    padding: 0;
    .hero-slides {
      .row {
        margin: 0;
        @include media-breakpoint-down(sm) {
          flex-direction: column-reverse;
        }
      }
    }
  }
  .details {
    .content {
      max-width: 540px;
      margin: 0 auto;
      padding: $grid-gutter-width 0;
      @include media-breakpoint-up(md) {
        padding: $grid-gutter-width*1.5 $grid-gutter-width/2;
      }
      @include media-breakpoint-up(lg) {
        padding: $grid-gutter-width*2 $grid-gutter-width/2;
      }
      @include media-breakpoint-up(xl) {
        padding: $grid-gutter-width*2.5 $grid-gutter-width/2;
      }
      h2 {
        font-size: $h1-md-font-size;
        line-height: .9;
      }
      .overline,h2,p {
        @include scaled-spacer('margin','bottom','xs');
      }
    }
  }
  .media {
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    padding: 0;
    min-height: 325px;
    @include media-breakpoint-up(sm) {
      min-height: 460px;
    }
    @include media-breakpoint-up(md) {
      min-height: 720px;
    }

  }
}
.marquee-block {
  // codepen: https://codepen.io/hexagoncircle/pen/eYMrGwW
  .overview {
    .style-svg {
      height: 90px;
    }
    .details {
      @include scaled-spacer('margin','bottom','sm');
      @include last-child-zero-margin;
      @include media-breakpoint-up(md) {
        width: 65%;
        margin-left: auto;
        margin-right: auto;
      }
      @include media-breakpoint-up(lg) {
        width: 60%;
      }
    }
  }
  .marquee {
    @include heading-text;
    font-size: $h1-md-font-size;
    display: flex;
    overflow: hidden;
    .content {
      @include list-normalize;
      white-space: nowrap;
      animation: scroll 20s linear infinite;
      @media (prefers-reduced-motion: reduce) {
        animation-play-state: paused !important;
      }
      li {
        white-space: nowrap;
        display: inline-block;
        padding: 0 .25em 0 .75em;
        position: relative;
        &:before {
          content: "\2022";
          position: absolute;
          left: 0;
        }
      }
    }
    &:nth-child(even) {
      .content {
        animation-direction: reverse;
      }
    }
    &:hover {
      .content {
        animation-play-state: paused;
      }
    }
  }
  .action {
    @include scaled-spacer('margin','top','sm');
    text-align: center;
  }
}
.two-column-block {
  h1,h2,h3,h4,h5,h6 {
    color: $body-color;
  }
  .embed-responsive {
    margin-bottom: 1rem;
  }
}

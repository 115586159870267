/* GRID SETTINGS
  --------------------------------------- */
$base-gutter-heights: (
  xs: 8px,
  sm: 12px,
  md: 14px,
  lg: 16px,
  xl: 18px
);
$base-gutter-scales: (
  xxs: .6,
  xs:  1,
  sm:  1.5,
  md:  2.5,
  lg:  4,
  xl:  6.5
);

$grid-gutter-width-base:  32px;
$grid-gutter-width:       $grid-gutter-width-base;

$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$header-height:         96px;



/* COLORS
  --------------------------------------- */
$orange:                #FF5E3D;
$marigold:              #FFC46A;
$sky:                   #9DC1CF;
$denim:                 #2E4659;
$bronze:                #867255;
$gray:                  #4E4F51;
$gray-light:            #F6F6F6;

$white:                 #fff;
$black:                 #0E0E0E;
$black-near:            #333C39;

$body-color:            $black;
$body-bg:               $white;

$brand-primary:         $orange;
$brand-secondary:       $sky;
$brand-teriary:         $marigold;

$link-color:            $black-near;
$link-decoration:       none;
$link-hover-color:      $brand-primary;
$link-hover-decoration: underline;
$link-transition:       all .15s ease-in-out;
$link-transition-slow:  all .35s ease-in-out;
$link-transition-btn:   padding .15s ease-in-out, all .15s ease-in-out;
$link-transition-move:  transform .15s ease-in-out;
$link-transition-bars:  opacity .2s, transform .2s;


/* FONTS
  --------------------------------------- */
$font-family-sans:      'Inconsolata', Helvetica, Arial, sans-serif;
$font-family-heading:   poleno, sans-serif;
$font-family-heading2:  'Work Sans', Verdana, "Verdana Ref", sans-serif;
$font-family-awesome:   'Font Awesome 6 Pro';
$font-family-base:      $font-family-sans;

$font-size-base:        1rem;       //   14  /  16  /  18
$font-size-sm:          .875rem;    //  12.2 /  14  / 15.7
$font-size-md:          .9375rem;   //  13.1 /  15  / 16.8
$font-size-lg:          1.125rem;   //  15.7 /  18  / 20.2
$font-size-xl:          1.5rem;     //   21  /  24  /  27

$h1-font-size:          2.666rem;   //  37.3 / 42.5 /  48
$h2-font-size:          2.222rem;   //  31.1 / 35.5 /  40
$h3-font-size:          1.777rem;   //  24.8 / 28.4 /  32
$h4-font-size:          1.333rem;   //  18.6 / 21.3 /  24
$h5-font-size:          1.111rem;   //  15.5 / 17.7 /  20
$h6-font-size:          1rem;       //   14  /  16  /  18

$h1-md-font-size:       3.555rem;   //  49.7 / 56.8 /  64
$h1-lg-font-size:       6.222rem;   //  87.1 / 99.5 / 112
$h1-xl-font-size:       8.888rem;   //124.4  /142.2 / 160

$nav-sm-font-size:      $h1-md-font-size;
$nav-md-font-size:      5.333rem;   //  74.6 / 85.3 /  96
$nav-lg-font-size:      $h1-lg-font-size;
$nav-xl-font-size:      7.111rem;   //  99.5 / 113.7/ 128

$line-height-base:      1.5;
$letter-spacing-base:   -.02em;

$font-weight-normal:    500;
$font-weight-semi:      600;
$font-weight-bold:      700;
$font-weight-extra:     800;
$font-weight-base:      $font-weight-normal;

$headings-font-family:  $font-family-heading;
$headings-font-weight:  $font-weight-semi;
$headings-line-height:  .9;

$headings2-font-family: $font-family-heading2;
$headings2-font-weight: $font-weight-extra;
$headings2-line-height: 1.2;


/* BUTTONS
  --------------------------------------- */
$btn-padding-y:               .66em;
$btn-padding-x:               1.4em;
$btn-font-family:             $headings2-font-family;
$btn-font-size:               $font-size-md;
$btn-font-weight:             $font-weight-base;
$btn-line-height:             1;
$btn-white-space:             nowrap;

$btn-border-width:            1px;

$btn-box-shadow:              none;
$btn-focus-box-shadow:        none;
$btn-active-box-shadow:       none;

$btn-border-radius:           4px;
$btn-border-radius-lg:        $btn-border-radius;
$btn-border-radius-sm:        $btn-border-radius;

$btn-default-color:           $black-near;
$btn-default-bg:              $white;
$btn-default-border:          $btn-default-bg;
$btn-default-color-hover:     $white;
$btn-default-bg-hover:        $black-near;
$btn-default-border-hover:    $btn-default-bg-hover;


/* CHAT BUBBLE TAIL POSITIONING
  --------------------------------------- */
$tail-horizontal:             -.3em;
$tail-vertical:               -.4em;
$tail-horizontal-md:          -.25em;
$tail-horizontal-lg:          -.125em;
$tail-vertical-lg:            -.375em;

$input-height:                3rem;


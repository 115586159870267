a.btn,.btn {
  @include transition($link-transition);
  @include letter-spaceing-normalize;
  line-height: $btn-line-height;
  cursor: inherit;
  color: $btn-default-color;
  background-color: $btn-default-bg;
  border-color: $btn-default-border;
  @include hover-focus {
    color: $btn-default-color-hover;
    background-color: $btn-default-bg-hover;
    border-color: $btn-default-border-hover;
  }
  &.btn-xl {
    @include allcaps;
    font-size: $h3-font-size;
    font-weight: $font-weight-bold;
  }
  &.btn-white {
    color: $denim !important;
    border-color: $denim !important;
    background-color: $white !important;
    @include hover-focus {
      color: $white !important;
      background-color: $denim !important;
      border-color: $denim !important;
    }
  }
}

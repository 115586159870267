/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;

  &:focus {
      outline: none;
  }

  &.dragging {
      cursor: pointer;
      cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;

  &:before,
  &:after {
      content: "";
      display: table;
  }

  &:after {
      clear: both;
  }

  .slick-loading & {
      visibility: hidden;
  }
}
.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  [dir="rtl"] & {
      float: right;
  }
  img {
      display: block;
  }
  &.slick-loading img {
      display: none;
  }

  display: none;

  &.dragging img {
      pointer-events: none;
  }

  .slick-initialized & {
      display: block;
  }

  .slick-loading & {
      visibility: hidden;
  }

  .slick-vertical & {
      display: block;
      height: auto;
      border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
    display: none;
}


@charset "UTF-8";

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-dot-color: $white !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-dot-character: "\2022" !default;
$slick-dot-size: 10px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: .6 !default;
$slick-opacity-not-active: 0.4 !default;

/* Dots */

.slick-dots {
  position: absolute;
  top: 270px;
  left: 50%;
  transform: translateX(-50%);
  list-style: none;
  display: block;
  text-align: center;
  padding: 0 6px 2px;
  margin: 0;
  width: auto;
  background-color: rgba($black,.2);
  border-radius: 20px;
  @include media-breakpoint-up(sm) {
    top: 410px;
  }
  @include media-breakpoint-up(md) {
    top: auto;
    bottom: $grid-gutter-width*1.25;
    left: 75%;
  }
  li {
    position: relative;
    display: inline-block;
    height: 10px;
    width: 10px;
    margin: 0 10px;
    padding: 0;
    cursor: pointer;
    button {
      border: 0;
      border-radius: 50%;
      background: $slick-dot-color;
      display: block;
      height: 10px;
      width: 10px;
      outline: none;
      line-height: 0px;
      font-size: 10px;
      color: transparent;
      padding: 0;
      cursor: pointer;
      opacity: $slick-opacity-not-active;
      &:hover, &:focus {
        outline: none;
        opacity: $slick-opacity-on-hover;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: $slick-dot-character;
        width: 10px;
        height: 10px;
        font-size: $slick-dot-size;
        line-height: 1;
        text-align: center;
        color: $slick-dot-color;
        opacity: $slick-opacity-not-active;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button{
      opacity: $slick-opacity-default;
      &:before {
        color: $slick-dot-color-active;
        opacity: $slick-opacity-default;
      }
    }
  }
}

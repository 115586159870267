html {
  font-size: 14px;
  height: 100%;
  scroll-behavior: smooth;
  scroll-padding-top: $header-height;
  @include media-breakpoint-up(md) {
    font-size: 16px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 18px;
  }
  body {
    @include color-block('white');
    @include base-text;
    padding-top: $header-height;
  }
}
a {
  @include transition($link-transition);
  &#skip-link {
    position:fixed;
    left:-999px;
    top: 0;
    z-index:1001;
    width:1px;
    height:1px;
    overflow:hidden;
    &:active, &:focus {
      top:8px;
      left: 8px;
      width: auto;
      height: auto;
      overflow:auto;
    }
  }
  &.lined {
    @include link-no-underline;
    @include heading2-text;
    font-size: $font-size-sm;
    line-height: 1;
    display: inline-block;
    padding: 0 0 .25rem 0;
    border-bottom-width: 2px;
    border-bottom-style: solid;
  }
}
img,svg {
  @include img-fluid;
}



.row {
  &.align-center {
    align-items: center;
  }
  &.justify-center {
    justify-content: center;
  }
}


/* FONTS
  --------------------------------------- */
h1,.h1,h2,.h2,h3,.h3,h4,.h4,h5,.h5,h6,.h6 {
  @include scaled-spacer('margin','bottom','sm');
}
h1,.h1 {
  @include heading-text-tight;
}
h2,.h2,h3,.h3 {
  @include heading-text;
}
h4,.h4,h5,.h5,h6,.h6 {
  @include heading2-text;
}
h1,.h1 {
  @include media-breakpoint-up(md) {
    @include resize-header('h1-md');
  }
  @include media-breakpoint-up(lg) {
    @include resize-header('h1-lg');
  }
  @include media-breakpoint-up(xl) {
    @include resize-header('h1-xl');
  }
}
.overline {
  @include allcaps;
  font-size: $font-size-base;
}

// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

// free time calculator
.form_pages {
  .form_h1 {
    .form_label {
      @include heading-text;
      @include resize-header('h2');
      @extend .header-color;
      text-align: center;
    }
  }
  .form_question {
    @include paragraph-spacing;
    select,input[type=text] {
      @extend .form-control;
      height: $input-height !important;
    }
  }
}
.form_button_submit {
  @extend .btn;
}

.page-header {
  @include scaled-spacer('padding','top','lg');
  @include scaled-spacer('padding','bottom','lg');
  @include page-header-frame;
  text-align: center;
  .home & {
    @include scaled-spacer('padding','top','md');
  }
  &>.container-fluid {
    @include fluid-container-margins;
    figure {
      @include scaled-spacer('margin','bottom','sm');
      img,svg {
        width: 100%;
      }
    }
  }
  .titles {
    @include last-child-zero-margin;
    p {
      @include scaled-spacer('margin','bottom','sm');
    }
    .home & {
      h1 {
        @include resize-header('h1');
      }
    }
  }
  .details {
    @include scaled-spacer('margin','top','sm');
    @include last-child-zero-margin;
    .home & {
      @include scaled-spacer('margin','top','xs');
    }
    .links {
      @include list-normalize;
      @include list-inline-block;
      @include scaled-spacer('margin','top','xxs');
      li {
        @include scaled-spacer('margin','top','xs');
        @include scaled-spacer('margin','right','xs');
        margin-bottom: 0;
        &:last-child {
          margin-right: 0;
        }
      }
    }
  }
  &.suppressed {
    display: none;
    visibility: hidden;
    &+.wrap {
      main.main {
        padding-top: 0;
        :first-child {
          &.ccv-block {
            margin-top: 0;
          }
        }
      }
    }
  }
}
.chat-bubbles {
  text-align: center;
  p {
    @include color-block('white');
    display: inline-block;
    padding: .5em .9em;
    border-radius: 48px;
    border: 1px solid $orange;
    line-height: 1;
    transform: rotate(-4.5deg);
    margin: 0;
    position: relative;
    &:before {
      content: url(../images/chat-tail.svg);
      position: absolute;
      left: $tail-horizontal;
      bottom: $tail-vertical;
      @include media-breakpoint-up(md) {
        left: $tail-horizontal-md;
      }
      @include media-breakpoint-up(lg) {
        left: $tail-horizontal-lg;
        bottom: $tail-vertical-lg;
      }
    }
    &+p {
      transform: rotate(4.5deg);
      top: 2em;
      left: -1.8em;
      &:before {
        transform: scaleX(-1);
        left: auto;
        right: $tail-horizontal;
        @include media-breakpoint-up(md) {
          right: $tail-horizontal-md;
        }
        @include media-breakpoint-up(lg) {
          right: $tail-horizontal-lg;
        }
      }
    }
  }
}







.speech-wrapper{
  .bubble{
    width: auto;
    height: auto;
    display: inline-block;
    border-radius: 48px;
    position: relative;
//     margin: 0 0 25px;
    &.alt{
//       margin: 0 0 0 60px;
    }
    padding: .4em .8em;
    text-align: center;
    color: $body-color;
    position: relative;

    .bubble-arrow {
      position: absolute;
      width: 0;
      bottom:42px;
      left: -16px;
      height: 0;
      &.alt{
        right: -2px;
        bottom: 40px;
        left: auto;
      }
    }
    .bubble-arrow:after {
      content: "";
      position: absolute;
      border: 0 solid transparent;
      border-top: 9px solid $orange;
      border-radius: 0 20px 0;
      width: 15px;
      height: 30px;
      transform: rotate(145deg);
    }
    .bubble-arrow.alt:after {
      transform: rotate(45deg) scaleY(-1);
    }
  }
}


.box1 {
  width: auto;
  display: inline-block;
  border: 1px solid $orange;
  padding: .4em;
  text-align: center;
  color: $body-color;
  position: relative;
}
.sb5:before {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 10px solid $orange;
  border-right: 10px solid transparent;
  border-top: 10px solid $orange;
  border-bottom: 10px solid transparent;
  right: -20px;
  top: 6px;
}
.sb5:after {
  content: "";
  width: 0px;
  height: 0px;
  position: absolute;
  border-left: 9px solid #fff;
  border-right: 9px solid transparent;
  border-top: 9px solid #fff;
  border-bottom: 9px solid transparent;
  right: -11px;
  top: 10px;
}

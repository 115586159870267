// Grid system
.main {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($main-sm-columns);
    .sidebar-primary & {
      @include make-col($main-sm-columns - $sidebar-sm-columns);
    }
  }
}
.sidebar {
  @include make-col-ready();
  @include media-breakpoint-up(sm) {
    @include make-col($sidebar-sm-columns);
  }
}

// body:not(.home):not(.single-team):not(.blog):not(.archive.category):not(.archive.author) {
body {
  &:not(.blog) {
    .main {
      @include main-column-width;
    }
  }
  &.blog { // articles, news, team, events list, single event
    .main {
      @include main-column-width-full;
    }
  }

}

footer.content-info {
  @include scaled-spacer('padding','top','md');
  @include scaled-spacer('padding','bottom','sm');
  @include heading2-text;
  font-size: $font-size-sm;
  font-weight: $font-weight-base;
  .brand {
    @include scaled-spacer('margin','bottom','md');
    display: block;
    max-width: 244px;
  }
  .nav-footer {
    @include scaled-spacer('margin','bottom','md');
    ul {
      @include list-normalize;
      column-count: 2;
      column-gap: 1.25rem;
      @include media-breakpoint-up(md) {
        column-count: 4;
      }
      li {
        @include scaled-spacer('margin','bottom','xs');
        line-height: 1.2;
        break-inside: avoid;
        a {

        }
      }
    }
  }
  .btn-xl {
    @include scaled-spacer('margin','bottom','md');
    width: 100%;
  }
  .slogan {
    @include scaled-spacer('margin','bottom','md');
    img,svg {
      width: 100%;
    }
  }
  .copyright {
    @include last-child-zero-margin;
    text-align: center;
  }
}

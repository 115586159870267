/* FUNCTIONS
  --------------------------------------- */
@function gutter-scale($size) {
  @return map-get($base-gutter-scales, $size);
}
@function gutter-height($breakpoint) {
  @return map-get($base-gutter-heights, $breakpoint);
}
@function map-deep-get($map, $keys...) {
  @each $key in $keys {
    $map: map-get($map, $key);
  }
  @return $map;
}
@function create_color_map($bg, $text, $headline, $headline-alt, $link, $hover, $icon, $btnbg, $btntext) {
  $map: (
    bg:           $bg,
    text:         $text,
    headline:     $headline,
    headline-alt: $headline-alt,
    link:         $link,
    hover:        $hover,
    icon:         $icon,
    btnbg:        $btnbg,
    btntext:      $btntext
  );
  @return $map;
}

/* COLORS
  --------------------------------------- */

/*1 name        2 bgcolor     3 text      4 headline    5 headline-alt  6 link      7 hover/line      8 icon      9 btnbg     10 btntext  */
$color-list: (
  white         $white        $black-near $orange       $black-near     $orange     $orange           $orange     $orange     $white,
  orange        $orange       $white      $white        $black-near     $white      $black-near       $white      $white      $black-near,
  sky           $sky          $black      $white        $black-near     $black-near $white            $black-near $white      $black-near,
  marigold      $marigold     $black      $black-near   $white          $black-near $white            $white      $white      $black-near,
  denim         $denim        $white      $marigold     $white          $marigold   $white            $marigold   $white      $black-near,
  bronze        $bronze       $white      $white        $marigold       $marigold   $white            $marigold   $white      $black-near,
  black-near    $black-near   $white      $marigold     $white          $marigold   $white            $marigold   $white      $black-near,
);

$color-map: ();

@each $value in $color-list {
  .bg-#{nth($value, 1)},
  .has-#{nth($value, 1)}-background-color {
    background-color: #{nth($value, 2)};
    color: #{nth($value, 3)};
    h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
      color: #{nth($value, 4)};
    }
    a {
      color: #{nth($value,6)};
      @include hover-focus {
        color: #{nth($value, 7)};
      }
      &.lined {
        color: #{nth($value, 3)};
        border-bottom-color: #{nth($value, 7)};
        @include hover-focus {
          color: #{nth($value, 7)};
        }
      }
    }
    .header-color {
      color: #{nth($value, 4)};
    }
    .header-color-alt {
      color: #{nth($value, 5)};
    }
    .btn:not(.btn-white) {
      background-color: #{nth($value, 9)};
      border-color: #{nth($value, 9)};
      color: #{nth($value, 10)};
      @include hover-focus {
        background-color: $btn-default-bg-hover;
        border-color: $btn-default-border-hover;
        color: $btn-default-color-hover;
      }
    }
    .style-svg {
      path {
        fill: #{nth($value, 8)};
      }
    }
  }
  $map: ();
  $key: #{nth($value, 1)};
  $map: map-merge($map, ($key: create_color_map(#{nth($value, 2)}, #{nth($value, 3)},#{nth($value, 4)},#{nth($value, 5)},#{nth($value, 6)},#{nth($value, 7)},#{nth($value, 8)},#{nth($value, 9)},#{nth($value, 10)})) );
  $color-map: map-merge($color-map, $map);
}
@mixin color-block($block-color) {
  background-color: map-deep-get($color-map, $block-color, "bg");
  color: map-deep-get($color-map, $block-color, "text");
  h1,h2,h3,h4,h5,h6,.h1,.h2,.h3,.h4,.h5,.h6 {
    color: map-deep-get($color-map, $block-color, "headline");
  }
  a {
    color: map-deep-get($color-map, $block-color, "link");
    @include hover-focus {
      color: map-deep-get($color-map, $block-color, "hover");
    }
    &.lined {
      color: map-deep-get($color-map, $block-color, "text");
      border-bottom-color: map-deep-get($color-map, $block-color, "hover");
      @include hover-focus {
        color: map-deep-get($color-map, $block-color, "hover");
      }
    }
  }
  .header-color {
    color: map-deep-get($color-map, $block-color, "headline");
  }
  .header-color-alt {
    color: map-deep-get($color-map, $block-color, "headlinealt");
  }
  .btn:not(.btn-white) {
    background-color: map-deep-get($color-map, $block-color, "btnbg");
    border-color: map-deep-get($color-map, $block-color, "btnbg");
    color: map-deep-get($color-map, $block-color, "btntext");
    @include hover-focus {
      background-color: $btn-default-bg-hover;
      border-color: $btn-default-border-hover;
      color: $btn-default-color-hover;
    }
  }
  .style-svg {
    path {
      fill: map-deep-get($color-map, $block-color, "icon");
    }
  }
}



/* HEIGHT SPACERS
  --------------------------------------- */
@mixin scaled-spacer($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: gutter-height(xs)*gutter-scale($scale);
  @include media-breakpoint-up(sm) { #{$csstype}-#{$csslocation}: gutter-height(sm)*gutter-scale($scale); }
  @include media-breakpoint-up(md) { #{$csstype}-#{$csslocation}: gutter-height(md)*gutter-scale($scale); }
  @include media-breakpoint-up(lg) { #{$csstype}-#{$csslocation}: gutter-height(lg)*gutter-scale($scale); }
  @include media-breakpoint-up(xl) { #{$csstype}-#{$csslocation}: gutter-height(xl)*gutter-scale($scale); }
}
@mixin scaled-spacer-negative($csstype,$csslocation,$scale) {
  #{$csstype}-#{$csslocation}: -(gutter-height(xs)*gutter-scale($scale));
  @include media-breakpoint-up(sm) { #{$csstype}-#{$csslocation}: -(gutter-height(sm)*gutter-scale($scale)); }
  @include media-breakpoint-up(md) { #{$csstype}-#{$csslocation}: -(gutter-height(md)*gutter-scale($scale)); }
  @include media-breakpoint-up(lg) { #{$csstype}-#{$csslocation}: -(gutter-height(lg)*gutter-scale($scale)); }
  @include media-breakpoint-up(xl) { #{$csstype}-#{$csslocation}: -(gutter-height(xl)*gutter-scale($scale)); }
}
@mixin full-padding-spacer($size) {
  @include scaled-spacer('padding','top',$size);
  @include scaled-spacer('padding','right',$size);
  @include scaled-spacer('padding','bottom',$size);
  @include scaled-spacer('padding','left',$size);
}




/* ELEMENTS
  --------------------------------------- */
@mixin d-flex {
  display: flex !important;
}
@mixin fluid-container-margins {
  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
  }
}
@mixin list-normalize-keep-margin {
  padding: 0;
  list-style: none;
}
@mixin list-normalize {
  @include list-normalize-keep-margin
  margin: 0;
}
@mixin list-inline-block {
  @include list-normalize;
  li {
    display: inline-block;
  }
}
@mixin last-child-zero-margin {
  &>:last-child {
    margin-bottom: 0;
  }
}
@mixin paragraph-spacing {
  @include scaled-spacer('margin','bottom','sm');
}
@mixin wp-block-spacing {
  @include scaled-spacer('margin','top','sm');
  @include scaled-spacer('margin','bottom','sm');
}

@mixin link-underline {
  text-decoration: underline;
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-underline-hover {
  @include hover-focus {
    text-decoration: underline;
  }
}
@mixin link-no-underline {
  text-decoration: none;
  @include hover-focus {
    text-decoration: none;
  }
}
@mixin no-padding {
  padding: 0;
}
@mixin page-header-frame {
  position: relative;
  .titles,.details {
    z-index: 1;
  }
  hr {
    width: 100%;
    height: 50px;
    background: transparent url(../images/page-header-decoration.svg) repeat-x 50% 0;
    background-size: auto 50px;
    margin: 0;
    border: none;
    position: absolute;
    bottom: -1px;
    left: 0;
    @include media-breakpoint-up(md) {
      height: 75px;
      background-size: auto 75px;
    }
    @include media-breakpoint-up(xl) {
      height: 120px;
      background-size: auto 140px;
    }
  }
}
@mixin block-frame {
  &:not(.bg-white) {
    position: relative;
    hr {
      width: 100%;
      margin: 0;
      border: none;
      position: absolute;
      left: 0;
      background-repeat: repeat-x;
      background-position: 50% 0;
      background-color: transparent;
      background-size: auto 100%;
      &.top {
        background-image: url(../images/block-decoration-top.svg);
        top: -1px;
        height: 32px;
        @include media-breakpoint-up(md) {
          height: 42px;
        }
        @include media-breakpoint-up(lg) {
          height: 52px;
        }
        @include media-breakpoint-up(xl) {
          background-size: 100% auto;
        }
      }
      &.bottom {
        background-image: url(../images/block-decoration-bottom.svg);
        bottom: -1px;
        height: 15px;
        @include media-breakpoint-up(md) {
          height: 20px;
        }
        @include media-breakpoint-up(lg) {
          height: 25px;
        }
        @include media-breakpoint-up(xl) {
          background-size: 100% auto;
        }
      }
    }
  }
  &.bg-white {
    hr {
      display: none;
    }
  }
}

/* FONTS
  --------------------------------------- */
@mixin letter-spaceing-normalize {
  letter-spacing: normal;
}
@mixin base-text {
  font-family: $font-family-base;
  font-weight: $font-weight-base;
  letter-spacing: $letter-spacing-base;
}
@mixin heading-text {
  @include letter-spaceing-normalize;
  line-height: 1;
  font-family: $headings-font-family;
  font-weight: $headings-font-weight;
  text-transform: uppercase;
}
@mixin heading-text-tight {
  @include heading-text;
  letter-spacing: -.019em;
  line-height: .7;
}
@mixin heading2-text {
  @include letter-spaceing-normalize;
  font-family: $headings2-font-family;
  font-weight: $headings2-font-weight;
  line-height: $headings2-line-height;
  text-transform: none;
}
@mixin allcaps {
  text-transform: uppercase;
  font-weight: $font-weight-semi;
  letter-spacing: .05em;
}
$heading-sizes: (
  h1: $h1-font-size,
  h2: $h2-font-size,
  h3: $h3-font-size,
  h4: $h4-font-size,
  h5: $h5-font-size,
  h6: $h6-font-size,
  h1-md: $h1-md-font-size,
  h1-lg: $h1-lg-font-size,
  h1-xl: $h1-xl-font-size
);
@mixin resize-header($size) {
  font-size: map-get($heading-sizes, $size);
}

/* COLUMNS
  --------------------------------------- */
@mixin wp-full-width-no-padding {
  width: 100vw;
  max-width: 100vw;
  margin: 0;
  margin-left: calc(50% - 50vw);
}
@mixin main-column-width {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(10);
    @include make-col-offset(1);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(8);
    @include make-col-offset(2);
  }
}
@mixin main-column-width-wide {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(10);
    @include make-col-offset(1);
  }
}

@mixin main-column-width-full {
  @include make-col-ready();
  @include make-col(12);
  @include media-breakpoint-up(md) {
    @include make-col(12);
    @include make-col-offset(0);
  }
  @include media-breakpoint-up(lg) {
    @include make-col(12);
    @include make-col-offset(0);
  }
}


@keyframes scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}
